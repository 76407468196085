<template>
  <div class="product">
    <van-nav-bar
      title="检测项列表"
      left-text="返回"    
      left-arrow   
      @click-left="toHome"  
    />
    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check='true'
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-search class="searchInp" v-model="searchValue" placeholder="请输入检测项关键词" shape='round' @clear='clearValue' @input="inputValue" fixed />            
      <van-cell-group class="mt10" v-for="(item, idx) in testList" :key="idx">
        <van-cell>
          <template #title>            
            <van-row type="" justify="space-between">              
              <van-col span="24"><h4 class="custom-title">检测项名：{{item.item_name}}</h4></van-col>                        
            </van-row>
            <van-row type="" justify="space-between" style="display: none;">                        
              <van-col span="24"><h4 class="custom-title">备注：{{item.remark}}</h4></van-col>
            </van-row>                        
            <van-row type="flex" justify="end">             
              <van-button plain hairline round type="info" size="mini" @click="detection(item)">详情</van-button>
              <van-button plain hairline round type="info" size="mini" @click="toEdit(item.id)">编辑</van-button>              
            </van-row>  
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>    
    <!-- 检测项新增 -->
    <van-popup v-model="addShow" class="elasticlayer tac" :closeable='true' position='bottom' round>
      <h3 class="tac">新增检测项</h3>
      <van-cell-group inset height='300px'>         
        <van-field v-model="itemName" required label="检测项名称" placeholder="请输入检测项名称"  />       
        <van-field v-model="remark" label="备注" placeholder="请输入备注"  />                              
      </van-cell-group>
      <van-button class="" round block size="small" type="primary"  @click="preservAdd" v-track="{triggerType:'click', title:'新增检测项',currentUrl: $route.path,behavior: this._data, duration: new Date().getTime()}" style="position: absolute;bottom: 30px;">保 存</van-button>
    </van-popup>
    <!-- 编辑 -->
    <van-popup v-model="toEditshow" class="elasticlayer tac" :closeable='true' position='bottom' round>
      <h3 class="tac">编辑检测项</h3>
      <van-cell-group inset height='300px'>                  
        <van-field v-model="itemName" required label="检测项名称" placeholder="请输入检测项名称"  />       
        <van-field v-model="remark" label="备注" placeholder="请输入备注"  />                
      </van-cell-group>
      <van-button class="" round block size="small" type="primary"  @click="preservEdit" v-track="{triggerType:'click', title:'修改检测项',currentUrl: $route.path,behavior: this._data, duration: new Date().getTime()}" style="position: absolute;bottom: 30px;">保 存</van-button>
    </van-popup>
    <div class="add-one" title="新增检测项" @click="addOne" >
      <van-icon name="plus" color="#fff" size="50" />
    </div>       
       <!-- 详情 -->
    <van-popup v-model="checkShow" class="elasticlayer" :closeable='true' position='bottom' round>
    <h3 class="tac">{{itemName}}详情</h3>
    <van-cell-group inset> 
      <van-cell title="检测项名称" :value="itemName" />
      <van-cell title="备注" :value="remark" />
    </van-cell-group>   
    </van-popup>    
  </div>
</template>
<script>
import Vue from 'vue';
import { List, Cell, CellGroup, Tag, Col, Row, Button, Popup, Field, Toast, Dialog, Icon, Search, DropdownMenu, DropdownItem, ActionSheet,NavBar } from 'vant';
import { parse } from 'qs';
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tag);
Vue.use(Col);
Vue.use(Row);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Icon);
Vue.use(Search);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(ActionSheet);
Vue.use(NavBar);
export default {  
  name: 'Testitem',
  components: {},
  
  data() {    
    return {
      count: 0,
      page: 1,
      offset: 10,      
      searchValue: '',      
      loading: false,
      finished: false,      
      addShow: false,
      toEditshow: false,
      editId: '', //编辑id  
      checkShow: false, //检测项详情弹窗         
      testList: [], //检测项列表 
      itemName: '', //检测项名称
      remark: '', //备注     
    };
  },
  created: function () {     
  },
  mounted() {    
  },
  methods: {          
    onLoad () {
      var params = {
        page: this.page++,
        offset: this.offset,
        item_name: this.searchValue
      }
      this.$get('check-item/get-check-item-list', 'api', params).then(res => {
        var data = res.data.list
        var length1 = res.data.list.length
        var length2 = res.data.count
        this.count = res.data.count
        this.loading = false;
        this.testList = this.testList.concat(data);//追加数据           
        if (length1 >= length2) {  //数据全部加载完成
          this.finished = true;         
        }
      }).catch(err => {
        console.log(err)
        this.finished = true;
      })      
    },
    // 搜索
    inputValue(value) { 
      this.searchValue = value    
      this.testList = []
      this.finished = false;
      this.loading = true 
      this.page = 1
      this.onLoad()                              
    },        
    // 点击 x
    clearValue() {
      this.testList = []
      this.finished = false;
      this.loading = true 
      this.page = 1
      this.onLoad()      
    },
    // 供应商详情
    detection(item) {       
      var that = this 
      this.checkShow = true     
      this.$post('check-item/get-check-item-detail', 'api', { id: item.id}).then(res => {    
        var data = res.data
        that.itemName = data.item_name      
        that.remark = data.remark
              
      }, err => {
        console.log(err)
        this.$notify({ type: 'warning', message: err.message })
      }) 
    },
    // 编辑
    toEdit(id) {
      var that = this
      that.editId = id
      that.$post('check-item/get-check-item-detail', 'api', { id: id}).then(res => {         
        var data = res.data 
        that.itemName = data.item_name
        that.remark = data.remark
        return        
      }, err => {
        console.log(err)
        this.$notify({ type: 'warning', message: err.message })
      })      
      this.toEditshow = true 
    },
    // 编辑保存
    preservEdit() {   
      var that = this
      this.toEditshow = false      
      var params = {
        id: this.editId,
        item_name: this.itemName,
        remark: this.remark,       
      }      
      this.$post('check-item/save-check-item', 'api', params).then(res => {
        this.$notify({ type: 'success', message: res.message })  
        this.finished = false;
        this.loading = true    
        that.page = 1
        that.testList = []
        that.onLoad()
      }, err => {
        console.log(err)
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    // 新增检测项
    addOne() {
      this.addShow = true    
      this.toEditshow = false
      this.itemName = ''
      this.remark = '' 
    },
    // 新增保存
    preservAdd() {      
      var that = this
      this.addShow = false      
      var params = {
        item_name: this.itemName,
        remark: this.remark,      
      }    
      this.$post('check-item/add-check-item', 'api', params).then(res => {
        this.$notify({ type: 'success', message: res.message })
        that.page = 1
        that.testList = []
        this.finished = false;
        this.loading = true 
        that.onLoad()
      }, err => {
        console.log(err)
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    // 返回
  toHome() {
    this.$router.push({
      path: '/home'     
    })
  }        
  }          
};
</script>
<style scoped>
 .status{
  position: absolute;
  top: 10px;
  left: 10px;
  width: 42px;
  height: 42px;
  z-index: 0;
  opacity: 40%;
  transform:rotate(-45deg);
  -webkit-transform:rotate(-45deg);
  -moz-transform:rotate(-45deg);
 }
 .border-tb{
  border-top: dashed 1px #f5f5f5;
  border-bottom: dashed 1px #f5f5f5;
  padding: 8px 0;
  margin: 8px 0;
 }
 .elasticlayer {
   height: 60%;
 }
 .tac {
   text-align: center;
 }
 .add-one {
   width: 50px;
   height: 50px;
   background: #1989fa;
   position: fixed;
   bottom: 100px;
   z-index: 999;
   border-radius: 50%;
   text-align: center; 
   opacity: 0.8;
   right: 0; 
   cursor: pointer;
 }
 .red {
   color: red;
 }
 .van-cell-group--inset {
    height: 300px;
    overflow: auto;
}
.icon-del {
  position: absolute;
  right: 20px;
  z-index: 99;
  top: 10px;
}
.icon-add {
  margin-left: 20px;
}
.searchInp {
  /* position: fixed;
  top: 0; */
  width: -webkit-fill-available;
  z-index: 99;
}
</style>